import React from "react"
import Layout from "./../../layout"
import ProductsGrid from "./ProductsGrid"
import Title from "./../../title"
import SidebarCategories from "./../../SidebarCategories"
import { graphql } from "gatsby"

const TemplateGridProducts = ({ data }) => {
  return (
    <Layout>
      <div className="container mt-xs-3">
        <Title color="blue" title="PRODUCTOS" withBorder={true} />
      </div>
      <div className="container__fluid">
        <div className="grid">
          <div className="col-12 col-sm-4 col-lg-4 col-xl-2">
            <SidebarCategories />
          </div>
          <div className="col-12 col-sm-8 col-lg-8 col-xl-9">
            <ProductsGrid listProducts={data?.allDatoCmsProducto?.nodes} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    allDatoCmsProducto(filter: { category: { slug: { eq: $slug } } }) {
      nodes {
        id
        nombre
        descripcion
        imagen {
          fluid(maxWidth: 191, maxHeight: 247) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`

export default TemplateGridProducts
