import React, { useState } from "react"
import ArrowDown from "../icon/ArrowDown"
import ArrowUp from "../icon/ArrowUp"
import useCategories from "./../../hooks/use-categories"

const SidebarCategories = () => {
  const [isActive, setIsActive] = useState(true)
  const categories = useCategories()

  const handleOnClick = () => setIsActive(!isActive)

  return (
    <div className="sidebar">
      <div className="grid grid__row-gap p-xs-0">
        <div className="col-12 p-xs-0">
          <div className="bg-blue-primary p-2 flex flex__justify-between">
            <span className="color-light">PRODUCTOS</span>
            <button
              onClick={handleOnClick}
              style={{
                background: "transparent",
                border: 0,
              }}
            >
              {isActive ? (
                <ArrowUp style={{ fill: "#ffffff" }} />
              ) : (
                <ArrowDown style={{ fill: "#ffffff" }} />
              )}
            </button>
          </div>
        </div>
        <div
          className="col-12 grid grid__row-gap m-0 p-xs-0"
          style={{
            transition: " max-height .1s ease-in-out",
            maxHeight: isActive ? "100%" : 0,
            overflow: "hidden",
          }}
        >
          {categories &&
            categories.map(category => (
              <div className="col-12 p-xs-0">
                <div className="bg-gray-secondary p-2">
                  <a href={`/${category.slug}`}>
                    <span className="color-blue-primary">{category.title}</span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarCategories
