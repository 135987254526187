import { graphql, useStaticQuery } from "gatsby"

const useCategories = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsCategory(sort: { fields: order }) {
        nodes {
          title
          slug
        }
      }
    }
  `)

  return data.allDatoCmsCategory.nodes
}

export default useCategories
